<template>
  <div class="apply-info">
    <!-- PC -->
    <div class="pcwork">
      <div class="title">{{ workFlow.formVar.formTitle }}</div>
      <span class="code">审批编号：{{ workFlow.businessKey }}</span>
      <div class="hr"></div>
      <div class="content">
        <div class="line">
          <div class="key">申请人</div>
          <div class="value">{{ workFlow.startUser.userName }}</div>
        </div>
        <div class="line">
          <div class="key">申请时间</div>
          <div class="value">{{ $parseTime(workFlow.startTime) }}</div>
        </div>
        <div class="line">
          <div class="key">所在部门</div>
          <div class="value">{{ orgFullName }}</div>
        </div>
        <h4>审批表单内容</h4>
        <div>
          <el-form
            :model="formData"
            ref="wfForm"
            :rules="rules"
            :disabled="editDisabled"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="分支流程" prop="msg">
                  <el-radio-group v-model="formData.msg">
                    <el-radio label="1">分支1</el-radio>
                    <el-radio label="2">分支2</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="路由地址" prop="address">
                  <el-input
                    v-model="formData.address"
                    placeholder="请输入地址"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <!-- PC end -->
    <div class="wf-container">
      <WorkFlow
        :businessKey="workFlow.businessKey"
        :formKey="workFlow.formKey"
        :formVar="workFlow.formVar"
        :formData="formData"
        @saveFormData="saveFormData"
        @workFlowFormData="initiaize_workflow_form"
      ></WorkFlow>
    </div>
  </div>
</template>

<script>
import WorkFlow from '@/components/WorkFlow/WorkFlow.vue'
import store from '@/store/index.js'
export default {
  components: { WorkFlow },
  name: 'testWorkFlow',
  data() {
    return {
      user: {},
      wfLoading: false,
      editDisabled: false,
      workFlow: {
        formKey: 'test',
        formVar: {
          formTitle: undefined,
          formUrl: undefined,
          msg: undefined
        },
        startUser: {},
        startTime: undefined,
        businessKey: undefined
      },
      formData: {
        msg: '1'
      },
      rules: {
        msg: [{ required: true, message: '分支不能为空', trigger: 'blur' }],
        address: [{ required: true, message: '地址不能为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    userName() {
      return store.state.user.uname
    },
    orgFullName() {
      if (this.workFlow.startUser.orgFullName) {
        return this.workFlow.startUser.orgFullName.replace('`', '-')
      } else {
        return ''
      }
    },
    msg() {
      return JSON.parse(JSON.stringify(this.formData.msg))
    }
  },
  created() {
    this.initiaize_form()
  },
  methods: {
    //工作流详情获取到回写方法
    initiaize_workflow_form(wf) {
      if (wf && wf.businessKey && wf.processInstanceId) {
        this.formData = JSON.parse(wf.formData)
        this.workFlow.formVar.formTitle = wf.taskSubject
        this.workFlow.formVar.formUrl = wf.taskUrl
        this.workFlow.startUser = wf.startUser
        this.workFlow.startTime = wf.startTime
        this.editDisabled = true
      }else{
          this.workFlow.startUser = wf.startUser
          this.workFlow.startTime = wf.startTime
      }
    },
    //初始化一个空的工作流
    initiaize_empty_form() {
      const date = new Date()
      const sid = parseInt(date.getTime()) + ''
      this.workFlow.businessKey = sid
      this.workFlow.formVar.formTitle = '测试审批表单[' + sid + ']'
      this.workFlow.formVar.formUrl = location.href + '?sid=' + sid
    },
    //初始化表单
    initiaize_form() {
      const sid = this.$urlParse.getUrlKey('sid')
      if (!sid) {
        this.initiaize_empty_form()
      } else {
        this.workFlow.businessKey = sid
      }
    },
    //工作流提交回写函数，回写成功后调用工作流送签函数
    saveFormData(callback) {
      this.$refs['wfForm'].validate((valid) => {
        if (valid) {
          const result = {
            errcode: 0,
            errmsg: ''
          }
          //业务逻辑处理
          return callback(result)
        }
      })
    }
  },
  watch: {
    msg: {
      deep: true,
      handler: function (val, old) {
        this.workFlow.formVar.msg = this.formData.msg
      },
      immediate: true //初始化页面后立即监听
    }
  }
}
</script>

<style scoped lang="scss">
.apply-info {
  .wxwork {
    position: relative;
    .line {
      margin: 10px 10px 0 10px;
      background: #fff;
      border-radius: 8px;
      padding: 12px;
      &__title {
        font-size: 17px;
        color: #333;
        font-weight: bold;
      }
      &__container {
        display: flex;
        margin-top: 10px;
        color: #333;
        .key {
          width: 60px;
          margin-right: 20px;
        }
        .value {
          flex: 1;
        }
      }
      &__container.code {
        margin-top: 0;
      }
    }
  }
  .pcwork {
    background: #fff;
    padding: 0 30px 0 30px;
    .title {
      font-weight: bold;
      font-size: 16px;
    }
    .code {
      font-size: 12px;
      margin-top: 5px;
      display: block;
    }
    .content {
      background: rgba(#3e6be1, 0.06);
      padding: 20px 20px 0 20px;
      border-radius: 2px;
      .title {
        font-weight: bold;
        font-size: 16px;
        padding: 20px 0;
      }
      .line {
        display: flex;
        flex-flow: column;
        .key {
          color: #333;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .value {
          color: #666;
          margin-bottom: 20px;
        }
      }
    }
    .hr {
      margin: 20px 0 30px 0;
      border-bottom: 1px solid #ccc;
    }
  }
  .wf-container {
    background: #fff;
    padding: 20px;
  }
}
@media (max-width: 599px) {
  .apply-info {
    margin-bottom: 70px;
  }
  .wxwork {
    display: block;
  }
  .pcwork {
    display: none;
  }
  .wf-container {
    margin: 10px;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 12px 20px 12px !important;
  }
}
@media (min-width: 599px) {
  .apply-info {
    margin-bottom: 70px;
  }
  .wxwork {
    display: block;
  }
  .pcwork {
    display: none;
  }
  .wf-container {
    margin: 10px;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 12px 20px 12px !important;
  }
}
@media (min-width: 960px) {
  .apply-info {
    margin-bottom: 0;
  }
  .wxwork {
    display: none;
  }
  .pcwork {
    display: block;
  }
  .wf-container {
    background: #fff;
    padding: 30px 30px 20px 30px !important;
    margin: 0;
    border-radius: 0;
  }
}
@media (min-width: 990px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1440px) {
}
</style>