import {post} from '@/plugins/request';
import { download } from '../../plugins/request';

// 查询模型分页列表
export function listModel(data) {
    return post('/activiti/v0.1/activiti/workflow/model/list',data);
  }
  
  // 查询模型详细
  export function getModel(data) {
    return post('/activiti/v0.1/activiti/workflow/model/detail',data);
  }
  
  // 新增模型
  export function addModel(data) {
    return post('/activiti/v0.1/activiti/workflow/model/create',data);
  }
  
  // 修改模型
  export function updateModel(data) {
    return post('/activiti/v0.1/activiti/workflow/model/update',data);
  }
  // 删除模型
  export function delModel(data) {
    return post('/activiti/v0.1/activiti/workflow/model/delete',data);
  }
  // 发布模型
  export function deployModel(data) {
    return post('/activiti/v0.1/activiti/workflow/model/deploy',data);
  } 
  //删除流程定义
  export function delProcessDefinition(data) {
    return post('/activiti/v0.1/activiti/workflow/processdefinition/delete',data);
  } 
  //更改流程定义状态
  export function changeDefinitionStatus(data) {
    return post('/activiti/v0.1/activiti/workflow/processdefinition/changeStatus',data);
  } 
  //查看部署图
  export function viewProcessDefinitionImage(data) {
    return download('/activiti/v0.1//activiti/workflow/processdefinition/viewImage',data);
  } 
  //获取流程定义列表
  export function getProcessDefineList(data) {
    return post('/activiti/v0.1/activiti/workflow/processdefinition/list',data);
  } 
    //获取流程实例列表
    export function getProcessInstanceList(data) {
      return post('/activiti/v0.1/activiti/workflow/processinstance/list',data);
    } 
  //获取待办定义列表
  export function getUncompleteTaskList(data) {
    return post('/activiti/v0.1/activiti/workflow/task/getAllUnCompleteTaskList',data);
  } 
  //获取我的待办任务列表
  export function getMyUncompletedTaskList(data) {
    return post('/activiti/v0.1/activiti/workflow/task/getUnCompleteTaskList',data);
  } 
  //获取我的已办任务列表
  export function getMyCompletedTaskList(data) {
    return post('/activiti/v0.1/activiti/workflow/task/getCompletedTaskList',data);
  } 
  //删除待办任务
  export function deleTask(data) {
    return post('/activiti/v0.1/activiti/workflow/wf/cancel',data);
  } 
  //查看任务流程图
  export function viewTask(data) {
    return download('/activiti/v0.1/activiti/workflow/wf/viewImage',data);
  } 
  //获取已完成的任务清单
  export function getCompletedTaskList(data){
    return post('/activiti/v0.1/activiti/workflow/task/getAllCompletedTaskList',data);
  }
  //转办任务
  export function transferTask(data){
    return post('/activiti/v0.1/activiti/workflow/wf/transfer',data);
  }
  //查看工作流
  export function detailWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/detail',data);
  }
  //提交工作流
  export function submitWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/submit',data);
  }
  //送签
  export function sendWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/send',data);
  }
  //作废
  export function cancelWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/cancel',data);
  }
  //撤回
  export function revokeWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/revoke',data);
  }
  //驳回
  export function rejectWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/reject',data);
  }
  //转签
  export function transferWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/operationTransfer',data);
  }
  //前加签
  export function addBeforeUserWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/addBeforeUserTaskSid',data);
  }
  //后加签
  export function addBehindUserWorkFlow(data){
    return post('/activiti/v0.1/activiti/workflow/wf/addBehindUserTaskSid',data);
  }
