<template>
  <div class="workflow" style="position: relative">
    <div class="workflow__title">
      <div>审批流程</div>
    </div>
    <div class="workflow__info" v-loading="wfLoading">
      <div
        class="workflow__info__line"
        v-for="(item, index) in workFlowEntity.sidList"
        :key="item.sid"
      >
        <div class="workflow__info__line__photo">
          <div :class="lineClass(item, index)"></div>
          <i :class="iconfontClass(item, index)">&#xe674;</i>
        </div>
        <div class="wxwork">
          <div class="workflow__info__line__setp">
            <span class="setp-user-title">
              <template v-if="index === 0">
                {{ item.nodeName || '申请人' }}
              </template>
              <template v-else> {{ item.nodeName || '审批人' }} </template>
            </span>
            <div
              class="setptitle"
              v-for="user in getTaskUser(item.userTaskList)"
              :key="user.userId"
            >
              <div class="setpusers">
                <span> {{ user.userName }}</span>
              </div>
            </div>
          </div>
          <div :class="commentClass(item.sid, index)">
            <div
              v-for="userTask in item.userTaskList"
              :key="userTask.userTaskId"
            >
              <div
                class="usermsg"
                v-for="pl in userTask.userCommentList"
                :key="pl.id"
              >
                <b>{{ pl.comment.split('&')[0] }}</b>
                <p>{{ pl.comment.split('&')[1] }}</p>
                <span
                  >{{ userTask.user.userName }}
                  {{ $parseTime(pl.datetime) }}</span
                >
              </div>
            </div>
            <div
              class="msgbox"
              v-if="
                (item.sid === workFlowEntity.currUserTaskSid && taskIdExist) ||
                (index === 0 &&
                  !workFlowEntity.currUserTaskSid &&
                  !workFlowEntity.processInstanceId)
              "
            >
              <div class="usersend">
                <div class="usersend__msg">
                  <span class="usersend__msg__title">意见详情</span>
                  <div class="msg">
                    <el-input
                      type="textarea"
                      :rows="4"
                      placeholder="请输入意见"
                      v-model="comment"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flowbuts">
      <el-button
        size="small"
        v-if="
          workFlowEntity.processInstanceId &&
          isCurrentUser &&
          !taskIdExist &&
          !workFlowEntity.endTime
        "
        @click="ACT_Revoke"
        type="primary"
        >撤回</el-button
      >
      <el-button
        size="small"
        v-if="
          taskIdExist &&
          workFlowEntity.processInstanceId &&
          workFlowEntity.currUserTaskSid == workFlowEntity.startUserTaskSid
        "
        @click="ACT_Cancel"
        type="primary"
        >作废</el-button
      >
      <el-button
        size="small"
        v-if="
          taskIdExist &&
          workFlowEntity.processInstanceId &&
          workFlowEntity.currUserTaskSid != workFlowEntity.startUserTaskSid &&
          !multiTaskIdExist
        "
        @click="ACT_Reject"
        type="primary"
        >驳回</el-button
      >
      <el-button
        size="small"
        v-if="
          taskIdExist &&
          workFlowEntity.processInstanceId &&
          workFlowEntity.currUserTaskSid != workFlowEntity.startUserTaskSid
        "
        @click="ACT_Transfer"
        type="primary"
        >转签</el-button
      >
      <el-button
        size="small"
        v-if="
          taskIdExist &&
          workFlowEntity.processInstanceId &&
          workFlowEntity.currUserTaskSid != workFlowEntity.startUserTaskSid &&
          !multiTaskIdExist
        "
        @click="ACT_Add_Before"
        type="primary"
        >前加签</el-button
      >
      <el-button
        size="small"
        v-if="
          taskIdExist &&
          workFlowEntity.processInstanceId &&
          workFlowEntity.currUserTaskSid != workFlowEntity.startUserTaskSid &&
          !multiTaskIdExist
        "
        @click="ACT_Add_Behind"
        type="primary"
        >后加签</el-button
      >
      <el-button
        size="small"
        v-if="!workFlowEntity.processInstanceId"
        @click="ACT_Confirm"
        type="primary"
        >提交</el-button
      >
      <el-button
        size="small"
        v-if="taskIdExist && workFlowEntity.processInstanceId"
        @click="ACT_Send"
        type="primary"
        >同意并送签</el-button
      >
    </div>
    <el-dialog
      title="驳回列表"
      :visible.sync="openReject"
      width="50%"
      append-to-body
    >
      <div class="popup-msg__container" v-loading="wfRejctLoading">
        <div class="popup-overrule-user">
          <el-radio
            v-model="toUserTaskSid"
            v-for="(item, index) in workFlowEntity.sidList"
            :key="item.sid"
            :label="item.sid"
            style="display: inline-flex; margin: 10px 0 0 0"
            :disabled="rejectRadioDisabled(index)"
          >
            <span class="mr5" style="float: left; margin: 0 10px 0 0">{{
              item.nodeName
            }}</span>
            <div
              v-for="userTask in item.userTaskList"
              :key="userTask.userTaskId"
              class="mb5 exam"
              style="float: left; margin: 0 10px 0 0"
            >
              <span class="mr5" style="float: left; margin: 0 10px 0 0"
                >({{ userTask.user.userName }})</span
              >
              <!-- <div
            v-for="(userComment, userindex) in userTask.userCommentList"
            :key="userindex"
            style="float:left;margin:0 10px 0 0;"
          >
            <span  >{{ userComment.comment.split('&')[0] }}</span
              >
          </div> -->
            </div>
          </el-radio>
          <span slot="footer" class="workflow__dialog-footer">
            <el-button @click="openReject = false">取消</el-button>
            <el-button type="primary" @click="ACT_Reject_Confirm"
              >确定</el-button
            >
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="转签-代办人选择"
      :visible.sync="openOrgPerson"
      width="50%"
      append-to-body
    >
      <div>
        <div style="background: white; position: fixed; top: 0"></div>
        <div class="org__search" style="display: inline-flex">
          <el-input
            placeholder="请输入用户名"
            v-model="queryPerson.loginName"
            clearable
            size="small"
            style="width: 300px"
          >
          </el-input>
          <el-input
            placeholder="请输入手机号"
            v-model="queryPerson.phone"
            clearable
            size="small"
            style="width: 300px"
          >
          </el-input>
          <div class="org__search__but" @click="searchPerson">
            <i class="iconfont">&#xe60b;</i>
          </div>
        </div>
      </div>
      <div class="popup-msg__container" v-loading="wfOrgpersonLoading">
        <el-table
          ref="multipleTable"
          :data="personTableData"
          :header-cell-class-name="cellClass"
          tooltip-effect="dark"
          style="width: 100%"
          empty-text="请输入用户名或手机号"
          @select="handleSelectionChange"
          @select-all="handleSelectionAll"
        >
          <el-table-column type="selection" fixed> </el-table-column>
          <el-table-column prop="name" label="姓名" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="phone" label="电话"> </el-table-column>
          <el-table-column prop="orgFullName" label="部门">
            <template slot-scope="scope">
              {{ getOrgFullName(scope.row.orgFullName) }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="uguid" label="工号"
          show-overflow-tooltip> </el-table-column> -->
        </el-table>
        <pagination
          :total="personTotalRecords"
          :page.sync="queryPerson.currentPage"
          :limit.sync="queryPerson.pageSize"
          @pagination="loadOrgPerson"
        />
        <div class="workflow__dialog-footer">
          <span slot="footer">
            <el-button @click="openOrgPerson = false">取消</el-button>
            <el-button type="primary" @click="ACT_OrgPerson_Confirm"
              >确定</el-button
            >
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  detailWorkFlow,
  submitWorkFlow,
  sendWorkFlow,
  cancelWorkFlow,
  revokeWorkFlow,
  rejectWorkFlow,
  transferWorkFlow,
  addBeforeUserWorkFlow,
  addBehindUserWorkFlow
} from '@/api/system/activiti'
import store from '@/store/index.js'
import { listUser } from '@/api/system/user'
export default {
  props: {
    businessKey: String,
    formKey: String,
    formVar: {
      String: Object
    },
    formData: {
      String: Object
    }
  },
  data() {
    return {
      workFlowEntity: {}, // 工作流实体对象存放
      wfLoading: false,
      isTransfer: false,
      confirmTxt: '提交',
      selectOperationValue: 'agreeSubmit', // 默认的意见操作
      comment: '',
      toUserTaskSid: undefined, //驳回的节点
      openReject: false,
      wfRejctLoading: false,
      openOrgPerson: false,
      wfOrgpersonLoading: false,
      queryPerson: {
        currentPage: 1,
        pageSize: 10,
        loginName: undefined,
        phone: undefined,
        orgGuid: undefined
      },
      personTableData: [],
      personTotalRecords: 0,
      selectOrgPersonIds: [], //勾选人员guid集合
      multipleSelect: false, //单选 or 多选
      orgPersonConfirmType: undefined //选人目的：transfer 转签  beforeAdd 前加签 behindAdd 后加签
    }
  },
  computed: {
    isCurrentUser() {
      let userId = store.state.user.uguid
      if (this.workFlowEntity.startUser.userId == userId) {
        return true
      } else {
        return false
      }
    },
    //当前节点位置
    currUserTaskSidIndex() {
      let index = 0
      for (let i = 0; i < this.workFlowEntity.sidList.length; i++) {
        if (
          this.workFlowEntity.sidList[i].sid ==
          this.workFlowEntity.currUserTaskSid
        ) {
          index = i - 1
          break
        }
      }
      return index
    },
    // 操作意见
    selectOperation: function () {
      if (
        this.workFlowEntity.startUserTaskSid ===
        this.workFlowEntity.currUserTaskSid
      ) {
        return [{ value: 'agreeSubmit', name: '同意并送签' }]
      } else {
        return [
          { value: 'agreeSubmit', name: '同意并送签' },
          // { value: 'disagreeReject', name: '不同意并驳回' },
          { value: 'refuse', name: '拒绝' }
        ]
      }
    },
    // 是否当前用户的任务
    taskIdExist: function () {
      if (
        (!this.workFlowEntity.currUserTaskSid &&
          !this.workFlowEntity.processInstanceId) ||
        (this.workFlowEntity.taskId !== null &&
          this.workFlowEntity.taskId !== '' &&
          this.workFlowEntity.taskId !== undefined)
      ) {
        return true
      } else {
        return false
      }
    },
    multiTaskIdExist() {
      let multiT = false
      if (this.workFlowEntity.taskId) {
        let currItem = this.workFlowEntity.sidList.find((item) => {
          return item.sid == this.workFlowEntity.currUserTaskSid
        })
        if (currItem && currItem.multiInstanceSid) {
          multiT = true
        }
      }
      return multiT
    }
  },
  created() {
    this.getWorkFlow()
  },
  methods: {
    //时间线样式
    lineClass(item, index) {
      let lineClassStr = ''
      if (index < this.workFlowEntity.sidList.length - 1) {
        lineClassStr += ' line '
        if (!this.workFlowEntity.deleReason) {
          if (this.workFlowEntity.endTime) {
            lineClassStr += ' verify agree '
          } else {
            if (
              index <= this.currUserTaskSidIndex &&
              this.workFlowEntity.processInstanceId
            ) {
              lineClassStr += ' verify agree '
            }
            if (
              item.sid == this.workFlowEntity.currUserTaskSid &&
              this.workFlowEntity.processInstanceId
            ) {
              lineClassStr += ' active '
            }
          }
        }
      }
      return lineClassStr
    },
    //iconfont样式
    iconfontClass(item, index) {
      let iconfontStr = 'iconfont def-user-photo'
      if (!this.workFlowEntity.deleReason) {
        if (this.workFlowEntity.endTime) {
          iconfontStr += ' verify agree '
        } else {
          if (
            index <= this.currUserTaskSidIndex &&
            this.workFlowEntity.processInstanceId
          ) {
            iconfontStr += ' verify agree '
          }
          if (
            item.sid == this.workFlowEntity.currUserTaskSid &&
            this.workFlowEntity.processInstanceId
          ) {
            iconfontStr += ' active '
          }
        }
      }
      return iconfontStr
    },
    //评论内容区域样式
    commentClass(sid, index) {
      let commentStr = 'workflow__info__line__msg'
      if (index === 0) {
        commentStr += ' index'
      }
      if (this.taskIdExist && sid == this.workFlowEntity.currUserTaskSid) {
        commentStr += ' task'
      }
      return commentStr
    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'DisableSelection'
      }
    },
    getOrgFullName(orgFullName) {
      return orgFullName.replace('`', '-')
    },
    getFormVarValue() {
      debugger
      let formVarV = {}
      formVarV = JSON.parse(JSON.stringify(this.formVar))
      formVarV.formData = JSON.stringify(this.formData)
      return formVarV
    },
    rejectRadioDisabled(index) {
      if (index > this.currUserTaskSidIndex) {
        return true
      } else {
        return false
      }
    },
    searchPerson() {
      this.queryPerson.currentPage = 1
      this.loadOrgPerson()
    },
    loadOrgPerson() {
      this.personTableData = []
      this.wfOrgpersonLoading = true
      listUser(JSON.stringify(this.queryPerson)).then((res) => {
        // debugger
        if (res.data.success) {
          if (res.data.result.total > 0) {
            this.personTotalRecords = res.data.result.total
            this.personTableData = res.data.result.records
            this.$nextTick(function () {
              this.personTableData.forEach((row) => {
                if (this.selectOrgPersonIds.indexOf(row.guid) > 0) {
                  this.$refs.multipleTable.toggleRowSelection(row, true)
                }
              })
            })
          }
        } else {
          this.$msgError(res.data.message)
        }
        this.wfOrgpersonLoading = false
      })
    },
    handleSelectionAll() {
      this.$refs.multipleTable.clearSelection()
    },
    handleSelectionChange(rows, row) {
      //单选
      const selected = rows.length && rows.indexOf(row) !== -1
      if (!this.multipleSelect) {
        this.selectOrgPersonIds = []
        this.personTableData.forEach((item) => {
          if (selected && item === row) {
            this.$refs.multipleTable.toggleRowSelection(item, true)
            this.selectOrgPersonIds.push(item.guid)
          } else {
            this.$refs.multipleTable.toggleRowSelection(item, false)
          }
        })
      } else {
        if (selected) {
          if (
            this.selectOrgPersonIds == 0 ||
            this.selectOrgPersonIds.indexOf(row.guid) < 0
          ) {
            this.selectOrgPersonIds.push(row.guid)
          }
        } else {
          if (this.selectOrgPersonIds.indexOf(row.guid) >= 0) {
            this.selectOrgPersonIds.pop(row.guid)
          }
        }
      }
      console.log(this.selectOrgPersonIds)
    },
    getTaskUser(listTask) {
      let users = []
      listTask.forEach((element) => {
        if (users && users.length > 0) {
          let hasUser = false
          users.forEach((user) => {
            if (user.userId == element.user.userId) {
              hasUser = true
            }
          })
          if (!hasUser) {
            let userTmp = {}
            userTmp.userId = element.user.userId
            userTmp.userName = element.user.userName
            users.push(userTmp)
          }
        } else {
          let userTmp = {}
          userTmp.userId = element.user.userId
          userTmp.userName = element.user.userName
          users.push(userTmp)
        }
      })
      return users
    },
    //获取工作流
    getWorkFlow() {
      let data = {
        formKey: this.formKey,
        businessKey: this.businessKey,
        formVar: this.getFormVarValue()
      }
      this.wfLoading = true
      detailWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.workFlowEntity = res.data.result
          this.selectOperationValue = 'agreeSubmit'
          this.comment = ''
          this.$emit('workFlowFormData', this.workFlowEntity)
          this.wfLoading = false
        } else {
          this.$msgError(res.data.message)
          this.wfLoading = false
        }
      })
    },
    //提交操作
    ACT_Confirm() {
      // 送签前调用父窗口进行业务校验
      let callWorkFlowFormResult = {
        errcode: 0,
        errmsg: ''
      }
      this.$emit('saveFormData', (val) => {
        debugger
        callWorkFlowFormResult = val
        if (callWorkFlowFormResult.errcode !== 0) {
          this.$msgError(callWorkFlowFormResult.errmsg)
          return false
        } else {
          // 送签操作
          let data = {
            key: this.formKey,
            businessKey: this.businessKey,
            formVar: this.getFormVarValue()
          }
          this.wfLoading = true
          submitWorkFlow(data).then((res) => {
            if (res.data.success) {
              //送签操作
              this.wfLoading = false
              this.ACT_Send()
            } else {
              this.$msgError(res.data.message)
              this.wfLoading = false
            }
          })
        }
      })
    },
    //送签操作
    ACT_Send() {
      let sendData = {
        businessKey: this.businessKey,
        formVar: this.getFormVarValue(),
        selectOperation: this.selectOperationValue,
        comment: this.comment
      }
      this.wfLoading = true
      sendWorkFlow(sendData).then((res) => {
        if (res.data.success) {
          this.wfLoading = false
          this.getWorkFlow()
        } else {
          this.wfLoading = false
          this.$msgError(res.data.message)
        }
      })
    },
    //作废操作
    ACT_Cancel() {
      if (!this.comment) {
        this.$msgError('意见不能为空!')
        return
      }
      let data = {
        businessKey: this.businessKey,
        deleReason: this.comment
      }
      this.wfLoading = true
      cancelWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.wfLoading = false
          this.getWorkFlow()
        } else {
          this.wfLoading = false
          this.$msgError(res.data.message)
        }
      })
    },
    //撤回操作
    ACT_Revoke() {
      let data = {
        businessKey: this.businessKey
      }
      this.wfLoading = true
      revokeWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.wfLoading = false
          this.getWorkFlow()
        } else {
          this.wfLoading = false
          this.$msgError(res.data.message)
        }
      })
    },
    //驳回操作
    ACT_Reject() {
      if (!this.comment) {
        this.$msgError('意见不能为空!')
        return
      }
      this.openReject = true
    },
    //驳回确认操作
    ACT_Reject_Confirm() {
      if (!this.toUserTaskSid) {
        this.$msgError('驳回目标节点不能为空!')
        return
      }
      let data = {
        businessKey: this.businessKey,
        toUserTaskSid: this.toUserTaskSid,
        comment: this.comment
      }
      this.wfRejctLoading = true
      rejectWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.wfRejctLoading = false
          this.openReject = false
          this.getWorkFlow()
        } else {
          this.wfRejctLoading = false
          this.openReject = false
          this.$msgError(res.data.message)
        }
      })
    },
    //选人操作路由
    ACT_OrgPerson_Confirm() {
      if (!this.orgPersonConfirmType) {
        this.$msgError('异常操作，请重试!')
        return
      }
      switch (this.orgPersonConfirmType) {
        case 'transfer':
          this.ACT_Transfer_Confirm()
          break
        case 'beforeAdd':
          this.ACT_Add_Before_Confirm()
          break
        case 'behindAdd':
          this.ACT_Add_Behind_Confirm()
          break
      }
    },
    //转签操作
    ACT_Transfer() {
      if (!this.comment) {
        this.$msgError('意见不能为空!')
        return
      }
      this.selectOrgPersonIds = []
      this.multipleSelect = false
      this.orgPersonConfirmType = 'transfer'
      this.openOrgPerson = true
      this.searchPerson()
    },
    //转签确认
    ACT_Transfer_Confirm() {
      if (!this.selectOrgPersonIds) {
        this.$msgError('代办人不能为空!')
        return
      }
      if (!this.selectOrgPersonIds.length > 1) {
        this.$msgError('代办人不能多个!')
        return
      }
      let userId = this.selectOrgPersonIds[0]
      let data = {
        businessKey: this.businessKey,
        toUserId: userId,
        comment: this.comment
      }
      this.wfOrgpersonLoading = true
      transferWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.wfOrgpersonLoading = false
          this.openOrgPerson = false
          this.getWorkFlow()
        } else {
          this.wfOrgpersonLoading = false
          this.openOrgPerson = false
          this.$msgError(res.data.message)
        }
      })
    },
    //前加签
    ACT_Add_Before() {
      if (!this.comment) {
        this.$msgError('意见不能为空!')
        return
      }
      this.selectOrgPersonIds = []
      this.multipleSelect = true
      this.orgPersonConfirmType = 'beforeAdd'
      this.openOrgPerson = true
      this.searchPerson()
    },
    //前加签确认
    ACT_Add_Before_Confirm() {
      if (this.selectOrgPersonIds.length == 0) {
        this.$msgError('加签办理人不能为空!')
        return
      }
      let data = {
        businessKey: this.businessKey,
        assigneeList: this.selectOrgPersonIds,
        comment: this.comment
      }
      this.wfOrgpersonLoading = true
      addBeforeUserWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.wfOrgpersonLoading = false
          this.openOrgPerson = false
          this.getWorkFlow()
        } else {
          this.wfOrgpersonLoading = false
          this.openOrgPerson = false
          this.$msgError(res.data.message)
        }
      })
    },
    //后加签
    ACT_Add_Behind() {
      if (!this.comment) {
        this.$msgError('意见不能为空!')
        return
      }
      this.selectOrgPersonIds = []
      this.multipleSelect = true
      this.orgPersonConfirmType = 'behindAdd'
      this.openOrgPerson = true
      this.searchPerson()
    },
    //后加签确认
    ACT_Add_Behind_Confirm() {
      if (this.selectOrgPersonIds.length == 0) {
        this.$msgError('加签办理人不能为空!')
        return
      }
      let data = {
        businessKey: this.businessKey,
        assigneeList: this.selectOrgPersonIds,
        comment: this.comment
      }
      this.wfOrgpersonLoading = true
      addBehindUserWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.wfOrgpersonLoading = false
          this.openOrgPerson = false
          this.getWorkFlow()
        } else {
          this.wfOrgpersonLoading = false
          this.openOrgPerson = false
          this.$msgError(res.data.message)
        }
      })
    }
  },
  watch: {
    formVar: {
      deep: true,
      handler: function (val, old) {
        if (this.businessKey) {
          this.getWorkFlow()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/style/index.scss';
.org__search {
  display: flex;
  padding: 0 10px 10px 0;
  &__but {
    background: #1890ff;
    color: #fff;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.workflow {
  background-color: #ffffff;
  &__dialog-footer {
    margin: 30px 0 0 0;
    text-align: center;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div:first-of-type {
      font-size: $fontSize-p-title;
      font-weight: bold;
    }
    & > div:last-of-type {
      cursor: pointer;
    }
  }
  &__info {
    margin-top: 20px;
    border-radius: $radius-default;
    border: 1px solid $primary;
    padding: 20px;
    &__line {
      padding: 20px 0;
      display: flex;
      position: relative;
      &__photo {
        position: relative;
        .line {
          height: 100%;
          width: 2px;
          background: #ccc;
          position: absolute;
          left: 14.5px;
          top: 35px;
        }

        .verify.agree.line {
          background: $primary;
        }
        .def-user-photo {
          position: relative;
          font-size: 30px;
          color: #ccc;
        }
        .def-user-photo.active {
          font-size: 30px;
          color: $primary;
        }
        .def-user-photo.verify {
          color: $primary;
        }
        .def-user-photo.verify::after {
          content: '\e612';
          color: #69d97b;
          font-size: 16px;
          position: absolute;
          bottom: -2px;
          left: 73%;
        }
        .refuse::after {
          content: '\e601';
          color: #ff0000;
          font-size: 16px;
          position: absolute;
          bottom: -2px;
          left: 73%;
        }
      }
      &__setp {
        width: 100px;
        display: flex;
        flex-flow: column;
        .setp-user-title {
          margin: 2.5px !important;
          color: $fontColor-gray;
          font-size: 12px;
        }
        .setptitle {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: flex-start;
          .setpname {
            border: 1px solid $fontColor-gray;
            color: $fontColor-gray;
            border-radius: $radius-default;
            padding: 2px;
            width: max-content;
          }
          .setpusers {
            margin: 0 0 5px 0;
            display: flex;
            flex-flow: column;
            span {
              margin: 0 2.5px;
            }
          }
        }
        .setpuseroption {
          display: flex;
          justify-content: flex-start;
          color: $primary;
          cursor: pointer;
          i {
            font-size: 20px;
            padding: 0 1px;
          }
        }
      }
      &__msg {
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .self {
          float: left;
          padding: 5px 10px;
          cursor: pointer;
          background-color: $primary;
          color: #ffffff;
          border-top-left-radius: $radius-default;
        }
        .index {
          background-color: transparent;
          color: #666;
        }
        .other {
          padding: 5px 10px;
          float: left;
          cursor: pointer;
          background-color: $color-gray;
          border-top-right-radius: $radius-default;
        }
        .usermsg {
          padding: 20px 20px 10px;
          border: 1px solid #eaeaea;
          border-radius: $radius-default;
          p {
            padding: 5px 0 10px;
          }
          span {
            display: block;
            text-align: right;
            color: $fontColor-gray;
            font-size: 12px;
          }
        }
        .msgbox {
          display: flex;
          flex-flow: column;
        }
        .usersend {
          border: 1px solid $color-gray !important;
          border-radius: $radius-default;
          border-top-left-radius: 0;
          padding: 20px;
          &__nowrite {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $color-gray;
            padding-bottom: 20px;
            & > div span:first-of-type {
              margin-right: 10px;
            }
            &__line {
              display: flex;
              align-items: center;
            }
          }
          &__msg {
            display: flex;
            span {
              margin-right: 10px;
            }
            .msg {
              flex: 1;
            }
          }
          .readonly {
            color: $fontColor-gray;
          }
        }
        .othersend {
          border: 1px solid $color-gray !important;
          padding: 10px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          border-radius: $radius-default;
          i {
            color: $primary;
            font-size: 20px;
            margin-left: 10px;
            cursor: pointer;
            position: relative;
            top: -1px;
          }
        }
        .flowbuts {
          margin-top: 10px;
        }
      }
      &__msg.index {
        background: $color-gray;
      }
    }
    &__line:last-of-type {
      border-bottom: 0;
    }
  }
}
.flowbuts {
  margin-top: 10px;
  text-align: center;
}
::v-deep .el-select {
  width: auto !important;
}
.popup-msg {
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  &__buts {
    height: 50px;
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    padding: 10px 10px 0 10px;
    display: flex;
    .maxbut {
      flex: 1;
      height: 32px;
    }
  }
  &__container {
    height: calc(100% - 70px);
    overflow-y: scroll;
  }
  &__txt {
    margin: 10px;
    border-radius: 3px;
    overflow: hidden;
  }
  ::v-deep {
    input:focus,
    textarea:focus {
      border: 0;
    }
  }
}
.popup-overrule-user {
  margin: 10px;
  background: #fff;
  padding: 15px;
  border-radius: 3px;
  display: flex;
  flex-flow: column;
  .p-v-uline {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .popup-transfer-user {
    margin: 10px;
    background: #fff;
    padding: 15px;
    border-radius: 3px;
    display: flex;
    flex-flow: column;
  }
}
@media (max-width: 599px) {
  ::v-deep .el-dialog {
    // width: 90vw !important;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 0;
  }
  .workflow__title {
    display: none;
  }
  .workflow__info {
    margin-top: 0;
    padding: 0;
    border: 0;
  }
  .workflow__info__line {
    padding: 20px 0 0 0;
    .workflow__info__line__photo {
      .line {
        height: calc(100% - 15px);
      }
    }
  }
  .wxwork {
    flex: 1;
    padding-left: 10px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    .workflow__info__line__setp {
      width: 100%;
      flex-flow: wrap;
      align-items: center;
      font-weight: bold;
      .setp-user-title {
        margin: 0 !important;
        color: #333;
      }
      .setptitle {
        .setpusers {
          margin: 0;
        }
      }
      .setpuseroption {
        font-weight: 400;
      }
    }
    .workflow__info__line__msg {
      margin-top: 10px;
      .self {
        padding: 0;
      }
    }
    .workflow__info__line__msg.task {
      display: none;
    }
    .workflow__info__line__msg.index {
      background: #fff;
      display: flex;
    }
  }
  .flowbuts {
    display: none;
  }
  .wxworkflowbuts {
    height: 60px;
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    .wfbs {
      display: flex;
      padding: 10px 10px 0 10px;
      .bs {
        flex: 1;
      }
    }
  }
}
@media (min-width: 599px) {
  ::v-deep .el-dialog {
    // width: 90vw !important;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 0;
  }
  .workflow__title {
    display: none;
  }
  .workflow__info {
    margin-top: 0;
    padding: 0;
    border: 0;
  }
  .workflow__info__line {
    padding: 20px 0 0 0;
    .workflow__info__line__photo {
      .line {
        height: calc(100% - 15px);
      }
    }
  }
  .wxwork {
    flex: 1;
    padding-left: 10px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    .workflow__info__line__setp {
      width: 100%;
      flex-flow: wrap;
      align-items: center;
      font-weight: bold;
      .setp-user-title {
        margin: 0 !important;
        color: #333;
      }
      .setptitle {
        .setpusers {
          margin: 0;
        }
      }
      .setpuseroption {
        font-weight: 400;
      }
    }
    .workflow__info__line__msg {
      margin-top: 10px;
      .self {
        padding: 0;
      }
    }
    .workflow__info__line__msg.task {
      display: none;
    }
    .workflow__info__line__msg.index {
      background: #fff;
      display: flex;
    }
  }
  .flowbuts {
    display: none;
  }
  .wxworkflowbuts {
    height: 60px;
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    .wfbs {
      display: flex;
      padding: 10px 10px 0 10px;
      .bs {
        flex: 1;
      }
    }
  }
}
@media (min-width: 960px) {
  ::v-deep .el-dialog {
    // width: 550px !important;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 20px;
  }
  .workflow__title {
    display: block;
  }
  .workflow__info {
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid #1890ff;
    padding: 20px;
  }
  .workflow__info__line {
    padding: 20px 0;
    display: flex;
    position: relative;
    .workflow__info__line__photo {
      .line {
        height: 100%;
      }
    }
  }
  .wxwork {
    display: flex;
    flex-flow: wrap;
    flex: 1;
    padding-left: 20px;
    align-items: inherit;
    .workflow__info__line__setp {
      width: 100px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      font-weight: 400;
      .setp-user-title {
        margin: 2.5px !important;
        color: #999;
        .setptitle {
          .setpusers {
            margin: 0 0 5px 0;
            display: flex;
            flex-flow: column;
          }
        }
        .setpuseroption {
          display: flex;
          justify-content: flex-start;
          cursor: pointer;
        }
      }
    }
    .workflow__info__line__msg {
      flex: 1;
      flex-flow: column;
      justify-content: center;
      margin-top: 0;
      .self {
        padding: 5px 10px;
      }
    }
    .workflow__info__line__msg.task {
      display: flex;
    }
    .workflow__info__line__msg.index {
      // background: #f8f8f8;
    }
  }
  .apply-info {
    margin-bottom: 0;
  }
  .flowbuts {
    display: block;
  }
  .wxworkflowbuts {
    display: none;
  }
}
@media (min-width: 990px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1440px) {
}
</style>